import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./views/home/home.js";
import UserProfile from "./views/userprofile/userprofile";
import MelodyMashChallenge from "./views/melodymashchallenge/melodymashchallenge";
import CommunityChallenges from "./views/communitychallenge/communitychallenge";
import Challenges from "./views/challenges/challenges.js";
import Videos from "./views/videos/videos.js";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import UserContext from "./UserContext";
import "./index.css";

function App() {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          // User is signed in, so we get the user details from Firestore.
          const userDoc = doc(db, "Users", firebaseUser.uid);
          const userDocSnapshot = await getDoc(userDoc);
          if (userDocSnapshot.exists()) {
            setUserDetails(userDocSnapshot.data());
          } else {
            // Handle the case where the user document does not exist.
            console.error(
              `No document found for user with ID: ${firebaseUser.uid}`
            );
          }
        } else {
          // User is signed out.
          setUserDetails(null);
        }
      }
    );

    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    console.log(userDetails);
  }, [userDetails]); // This useEffect will run every time `userDetails` changes.

  return (
    <UserContext.Provider value={userDetails}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user/:id" element={<UserProfile />} />
          <Route path="/challenges" element={<Challenges />} />
          <Route path="/videos" element={<Videos />} />
          <Route
            path="/communitychallenge/:id"
            element={<CommunityChallenges />}
          />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
