import React, { useState } from 'react';
import './videothumbnailcard.css';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';

const VideoThumbnailCard = ({ title, user, style, instruments, challenge, videoID }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const thumbnailUrl = `https://img.youtube.com/vi/${videoID}/0.jpg`;

  return (
    <div className="video-thumbnail-card">
      <div
        className="video-thumb"
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
        onClick={openModal}
      ></div>
      <div className='video-thumbnail-text'>
        <h3>{title}</h3>
        <p>{user}</p>
        <p>{style}</p>
        <p>{instruments}</p>
        <p>{challenge}</p>
      </div>

      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <iframe
          width="700"
          height="391"
          src={`https://www.youtube.com/embed/${videoID}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <Box m={2}>
          <button onClick={closeModal}>Close</button>
        </Box>
      </Dialog>
    </div>
  );
};

export default VideoThumbnailCard;
