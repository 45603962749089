import React, { useState, useEffect } from 'react';
import './userprofile.css';
import { Avatar, Tabs, Tab, Typography, Box, Grid, MobileStepper } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import VideoThumbnailCard from '../../components/videothumbnailcard/videothumbnailcard';
import Navbar from '../../components/navbar/navbar';

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ minHeight: '350px' }} // Add this line
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};


const UserProfile = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [userData, setUserData] = useState(null);
  const [videos, setVideos] = useState([]);
  const [badges, setBadges] = useState([]);
  const { id } = useParams();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Set the breakpoint based on your design
  const [openLightbox, setOpenLightbox] = useState(false);
  const [lightboxContent, setLightboxContent] = useState(null);


  const userName = 'John Doe';
  const userPoints = 1234; // You can replace this with actual data

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenLightbox = (video) => {
    setLightboxContent(video);
    setOpenLightbox(true);
  };
  

  useEffect(() => {
    // Replace with your actual API URLs
    const userApiURL = `https://1cw99kg8si.execute-api.ap-southeast-2.amazonaws.com/mm/users/${id}`;
    const videosApiURL = `https://1cw99kg8si.execute-api.ap-southeast-2.amazonaws.com/mm/videos/user/${id}`;
    const badgesApiURL = 'https://api.example.com/badges';
  
    // Fetch user data
    fetch(userApiURL)
      .then(response => response.json())
      .then(data => setUserData(data));
      
    // Fetch videos
    fetch(videosApiURL)
      .then(response => response.json())
      .then(data => setVideos(data));
  
    // Fetch badges
    fetch(badgesApiURL)
      .then(response => response.json())
      .then(data => setBadges(data));
  }, []);
  

  return (
    <div className="user-profile-container">
    <div className="user-profile">
    <Navbar />
    {userData ? (
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={4} md={3}>
          <div className="avatar-container">
            <Avatar className="profile-avatar" alt="User Name" src={userData.userImage} />
            <Typography variant="h5" className="user-name">
              {userData.userName}
            </Typography>
            <Typography className="user-bio">
              Points: {userData.points}
            </Typography>
            <Typography className="user-bio">{userData.description}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <div className="menu-container">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="User profile tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Videos" />
            <Tab label="Badges" />
            <Tab label="Promote" />
          </Tabs>
          {isMobile && (
          <MobileStepper
            variant="dots"
            steps={4} // The number of tabs
            position="static"
            activeStep={tabValue}
            nextButton={
              <Button size="small" onClick={() => setTabValue((prevValue) => prevValue + 1)}>
                Next
                <KeyboardArrowRightIcon />
              </Button>
            }
            backButton={
              <Button size="small" onClick={() => setTabValue((prevValue) => prevValue - 1)}>
                <KeyboardArrowLeftIcon />
                Back
              </Button>
            }
            style={{ backgroundColor: 'transparent' }} // Add this line
          />
          )}
        </div>
        <TabPanel value={tabValue} index={0}>
          {videos.length === 0 ? (
            <Typography>No videos found.</Typography>
          ) : (
            <Grid container spacing={2}>
              {videos.map((video, index) => (
          <VideoThumbnailCard
            key={index}
            title={video.title}
            user={video.userName}
            style={video.style}
            instruments={video.instruments}
            challenge={video.challenge}
            videoID={video.videoID} // Add the videoID property
          />
        ))}
            </Grid>
          )}
        </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Typography>Badges achieved</Typography>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
          <iframe style={{borderRadius: '12px'}} src="https://open.spotify.com/embed/artist/0IaABkuV8yeBXaF0NCYQEC?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </TabPanel>
        </Grid>
      </Grid>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
    <Dialog
      open={openLightbox}
      onClose={() => setOpenLightbox(false)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      {lightboxContent && (
        <React.Fragment>
          <iframe
            title={lightboxContent.title}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${lightboxContent.videoID}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              borderRadius: '12px',
            }}
          ></iframe>
          <Box m={2}>
            <Typography variant="h6">{lightboxContent.title}</Typography>
          </Box>
        </React.Fragment>
      )}
    </Dialog>


    </div>
  );
};

export default UserProfile;